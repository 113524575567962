import Paper from "@material-ui/core/Paper";
import {Box} from "@mui/material";
import WarningIcon from "@material-ui/icons/Warning";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import React from "react";
import PropTypes from "prop-types";

export function ErrorMessage(props) {
    if (!props.message) {
        return <></>;
    }
    return (
        <Paper elevation={1}>
            <Box display="flex" marginTop={2} borderRadius={1} padding={1} justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <WarningIcon color="error"/>
                    <Box paddingLeft={1}>
                        <Typography variant="subtitle2" color="error">{props.message}</Typography>
                    </Box>
                </Box>
                {props.onClickClearError && <Box>
                    <IconButton onClick={() => props.onClickClearError()}><ClearOutlinedIcon/></IconButton>
                </Box>}
            </Box>
        </Paper>
    );
}

ErrorMessage.propTypes = {
    message: PropTypes.string,
    onClickClearError: PropTypes.func,
};
