import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from "@material-ui/core/Chip/Chip";
import Avatar from "@material-ui/core/Avatar/Avatar";
import Grid from "@material-ui/core/Grid/Grid";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import CategoryPicker from "./CategoryPicker";
import {toMapById} from "../utils/utils";
import {makeStyles} from "@material-ui/core";
import AmountInput from "../UtilComponents/AmountInput";

function UserSelect(props) {
    const [value, setValue] = useState(props.value);
    const usersById = useMemo(() => toMapById(props.availableUsers), [props.availableUsers]);

    const handleChange = e => {
        setValue(e.target.value);
        props.onChange(e.target.value);
    };

    return (
        <FormControl {...props.rootProps}>
            <InputLabel component="label">User</InputLabel>
            <Select  {...props.selectProps}
                     value={value}
                     onChange={handleChange}
                     disabled={props.availableUsers.length === 1}
                     renderValue={selected => (
                         <Chip variant={'outlined'}
                               avatar={<Avatar alt={usersById[selected].name}
                                               src={usersById[selected].pictureUrl}/>}
                               label={usersById[selected].name}
                         />
                     )}
            >
                {props.availableUsers.map(user =>
                    <MenuItem key={user.id} value={user.id}>{user.name}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

UserSelect.propTypes = {
    rootProps: PropTypes.object,
    selectProps: PropTypes.object,
    value: PropTypes.number.isRequired,
    availableUsers: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

const useStyles = makeStyles(theme => ({
    paper: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        width: theme.breakpoints.only("md") - theme.spacing(10),
    },
    amountInput: {
        height: "100%"
    },
    deleteContainer: {
        height: "100%"
    },
}));

function EditExpenseForm(props) {
    const classes = useStyles();
    const [cacheBuster, setCacheBuster] = useState(() => new Date().getTime());
    const [state, setState] = useState({
        amount: props.amount,
        userId: props.userId || props.currentUserId,
        categoryId: props.categoryId,
    });

    useEffect(() => {
            setState(prevState => {
                return {...prevState, amount: props.amount};
            });
            setCacheBuster(new Date().getTime());
        },
        // eslint-disable-next-line
        [props.cacheBuster]);

    const handleChange = prop => {
        const newState = {...state, ...prop};
        setState(newState);
        props.onChange(newState);
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Grid container spacing={1} justifyContent="space-between">
                <Grid item xs={2}>
                    <AmountInput rootProps={{fullWidth: true, className: classes.amountInput}}
                                 inputProps={{className: classes.amountInput}}
                                 value={state.amount}
                                 onChange={v => handleChange({amount: v})}
                    />
                </Grid>
                <Grid item xs={3}>
                    <UserSelect rootProps={{fullWidth: true}}
                                value={state.userId}
                                availableUsers={props.availableUsers}
                                onChange={v => handleChange({userId: v})}/>
                </Grid>
                <Grid item xs={5}>
                    <CategoryPicker categoryPickerId={props.formKey}
                                    categoryId={state.categoryId}
                                    availableCategories={props.availableCategories}
                                    onSave={v => handleChange({categoryId: v})}
                    />
                </Grid>
                <Grid item>
                    <Grid container spacing={0} alignItems="center" justifyContent="center"
                          classes={{root: classes.deleteContainer}}>
                        <Grid item>
                            <IconButton onClick={() => props.onDelete()}>
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

EditExpenseForm.propTypes = {
    formKey: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    userId: PropTypes.number,
    categoryId: PropTypes.number,
    currentUserId: PropTypes.number.isRequired,
    availableUsers: PropTypes.array.isRequired,
    availableCategories: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    cacheBuster: PropTypes.number.isRequired,
};

export default EditExpenseForm;
