import React from 'react';
import * as PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";

const useStyles = makeStyles(theme => ({
    controlButtons: {
        marginLeft: theme.spacing(1)
    },
}));

function ControlButtons(props) {
    const classes = useStyles();

    return (
        <Grid container justifyContent="flex-end" spacing={0}>
            <Grid item>
                <Button onClick={() => props.onClickDelete()}
                        color={"secondary"}
                        size={props.size}
                        variant={"outlined"}
                        className={props.buttonClassName ? props.buttonClassName : classes.controlButtons}
                        disabled={props.isDeleteDisabled}>
                    Delete
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => props.onClickCancel()}
                        color={"default"}
                        size={props.size}
                        variant={"outlined"}
                        className={props.buttonClassName ? props.buttonClassName : classes.controlButtons}
                        disabled={props.isCancelDisabled}>
                    Cancel
                </Button>
            </Grid>
            <Grid item>
                <Button onClick={() => props.onClickSave()}
                        color={"primary"}
                        size={props.size}
                        variant={"outlined"}
                        className={props.buttonClassName ? props.buttonClassName : classes.controlButtons}
                        disabled={props.isSaveDisabled}>
                    Save
                </Button>
            </Grid>
        </Grid>
    );
}

ControlButtons.propTypes = {
    onClickSave: PropTypes.func.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickDelete: PropTypes.func.isRequired,
    isSaveDisabled: PropTypes.bool,
    isCancelDisabled: PropTypes.bool,
    isDeleteDisabled: PropTypes.bool,
    size: PropTypes.oneOf(["small", "medium", "large"]),
    buttonClassName: PropTypes.string,
};

export default ControlButtons;
