import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button/Button";
import {Typography, withStyles} from "@material-ui/core";
import API from "../api";
import AllInbox from '@material-ui/icons/AllInbox';
import Notifications from '@material-ui/icons/Notifications';
import Warning from '@material-ui/icons/Warning';
import {PATH_JOBS} from "../config";
import Paper from "@material-ui/core/Paper";
import Popover from "@material-ui/core/Popover";
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from "@material-ui/core/Grid";
import JobsProgress from "./JobsProgress";

const styles = theme => ({
    button: {
        minWidth: 0,
        marginRight: theme.spacing(2),
    },
    notificationIcon: {
        position: 'absolute',
        transform: 'translate(' + theme.spacing(2.5) + 'px, ' + -theme.spacing(0.5) + 'px) scale(0.5)',
        transformOrigin: 'top left',
    },
    paper: {
        maxWidth: 500,
    },
    popover: {
        maxHeight: 500,
    },
    noJobInfo: {
        padding: theme.spacing(2),
    }
});

class Jobs extends Component {

    constructor(props) {
        super(props);

        this.state = {
            jobs: [],
            isLoading: true,
            error: null,
            anchorElement: null,
            open: false,
        };
    }

    componentDidMount() {
        this.reload();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    reload() {
        API.get(PATH_JOBS)
            .then(result => this.setState({
                jobs: result.data.jobs,
                error: null,
                isLoading: false
            }, () => this.setIntervalForReloading()))
            .catch(error => {
                this.setState({
                    isLoading: false,
                    error: error
                });
                this.setIntervalForReloading();
            });
    }

    handleClick = event => {
        this.reload();
        this.setState({
            anchorElement: event.currentTarget,
            open: true,
        });
    };

    handleClose = () => {
        this.setState({
            anchorElement: null,
            open: false,
        });
    };

    render() {
        const {classes} = this.props;
        const {anchorElement, open} = this.state;

        return (
            <div>
                {this.showJobsButton()}
                <Popover className={classes.popover}
                         open={open} anchorEl={anchorElement} onClose={this.handleClose}
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'right',
                         }}
                         transformOrigin={{
                             vertical: 'top',
                             horizontal: 'right',
                         }}
                >
                    <Paper elevation={8} >
                        {this.showJobsPopover()}
                    </Paper>
                </Popover>
            </div>
        );
    }

    showJobsButton() {
        const {classes} = this.props;

        if (this.state.isLoading) {
            return <Button className={classes.button}>
                <CircularProgress/>
            </Button>;
        }

        if (this.areJobsInProgress()) {
            return <Button className={classes.button} onClick={this.handleClick}>
                <AllInbox/>
                <Notifications className={classes.notificationIcon}/>
            </Button>;
        } else {
            return <Button className={classes.button} onClick={this.handleClick}>
                <AllInbox/>
                {this.state.error ? <Warning className={classes.notificationIcon}/> : null}
            </Button>;
        }
    }

    areJobsInProgress() {
        return this.state.jobs.some(job => job.finishedTsMs === 0);
    }

    showJobsPopover() {
        if (this.state.error) {
            return <Typography className={this.props.classes.noJobInfo}>{this.state.error.message}</Typography>;
        }
        if (!this.state.jobs || !this.state.jobs.length) {
            return <Typography className={this.props.classes.noJobInfo}>No jobs</Typography>;
        }
        return <Grid container>
            {this.state.jobs.map(job =>
                <JobsProgress key={job.id}
                              submittedTsMs={job.submittedTsMs}
                              startedTsMs={job.startedTsMs}
                              finishedTsMs={job.finishedTsMs}
                              description={job.description}
                              progressPct={job.progressPct}
                              error={job.error}
                />
            )}
        </Grid>;

    }

    setIntervalForReloading() {
        if (this.state.open || this.areJobsInProgress()) {
            this.timer = setTimeout(() => this.reload(), 1000); // poll every second if in progress or open
        } else {
            this.timer = setTimeout(() => this.reload(), 10000); // poll every 10 seconds
        }
    }
}

Jobs.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, {withTheme: true})(Jobs);
