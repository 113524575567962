import React, {useEffect} from 'react';
import {changeTitle} from "../utils/utils";
import {makeStyles, useTheme} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({}));

function Labs() {
    const theme = useTheme();
    const classes = useStyles();
    useEffect(() => changeTitle('Labs'), []);

    return (<>
        <Typography variant="h4" gutterBottom>Labs</Typography>
        <Typography>Cool stuff coming soon!</Typography>
    </>);
}

export default Labs;
