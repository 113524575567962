import React, {useMemo, useState} from "react";
import API, {useDataApi} from '../api';
import {PATH_ACCOUNTS, PATH_PARSERS, PATH_USERS} from "../config";
import Select from "@material-ui/core/Select/Select";
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Grid from "@material-ui/core/Grid/Grid";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import ControlButtons from "../UtilComponents/ControlButtons";
import MultiSelectChips from "../UtilComponents/MultiSelectChips";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

function EditableAccountCard(props) {
    const [accountName, setAccountName] = useState(props.account ? props.account.name : "");
    const [parser, setParser] = useState(props.account ? props.account.parser : "");
    const [userIds, setUserIds] = useState(props.userIds ? props.userIds : []);
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [actionError, setActionError] = useState(undefined);
    const [usersState] = useDataApi(PATH_USERS, {users: []});
    const [parsersState] = useDataApi(PATH_PARSERS, {parsers: [parser]});

    const availableUsers = useMemo(() => usersState.data.users, [usersState.data.users]);
    const availableParsers = useMemo(() => parsersState.data.parsers, [parsersState.data.parsers]);

    const handleSave = () => {
        setIsSaving(true);

        const data = {
            "name": accountName,
            parser,
            userIds,
        };

        const axiosPromise = props.account
            ? API.put(`${PATH_ACCOUNTS}/${props.account.id}`, data)
            : API.post(PATH_ACCOUNTS, data);
        axiosPromise.then(() => props.onChangeAction())
            .catch(error => {
                setActionError(error.message);
            });
    };

    const handleDelete = () => {
        setIsDeleting(true);
        API.delete(PATH_ACCOUNTS + "/" + props.account.id)
            .then(() => props.onChangeAction())
            .catch((error) => {
                setActionError(error.response.data.message);
            });
    };

    const handleAccountNameChange = event => {
        setAccountName(event.target.value);
    };

    const handleParserChange = event => {
        setParser(event.target.value);
    };

    if (usersState.error || parsersState.error) {
        return <Card><Typography variant="subtitle1">
            {usersState.error ? usersState.error.message : parsersState.error.message}
        </Typography></Card>;
    }

    return <Card>
        <CardContent>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Account Name</InputLabel>
                        <Input value={accountName} onChange={handleAccountNameChange}/>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Parser</InputLabel>
                        <Select value={parser} onChange={handleParserChange}>
                            <MenuItem value="" disabled>Select a parser</MenuItem>
                            {availableParsers.map(parser => <MenuItem key={parser} value={parser}>{parser}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <MultiSelectChips inputTitle="Users"
                                      availableItems={availableUsers}
                                      handleChangeMultiSelect={setUserIds}
                                      preSelectedIds={userIds}
                    />
                </Grid>
            </Grid>
        </CardContent>
        <CardActions>
            {actionError ? <Typography variant="caption" color="error">{actionError}</Typography> : ""}
            <ControlButtons onClickSave={handleSave}
                            onClickCancel={props.cancelButtonAction}
                            onClickDelete={handleDelete}
                            isSaveDisabled={isSaving}
                            isDeleteDisabled={props.account === undefined || isDeleting}
            />
        </CardActions>
    </Card>;
}

EditableAccountCard.propTypes = {
    account: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        parser: PropTypes.string.isRequired,
    }),
    userIds: PropTypes.arrayOf(PropTypes.number),
    cancelButtonAction: PropTypes.func,
    onChangeAction: PropTypes.func.isRequired
};

export default EditableAccountCard;
