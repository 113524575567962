import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";

function AmountInput(props) {
    const [displayValue, setDisplayValue] = useState(() => (props.value / 100).toFixed(props.decimals));
    // eslint-disable-next-line
    useEffect(() => setDisplayValue((props.value / 100).toFixed(props.decimals)), [props.value]);

    const handleChange = e => {
        setDisplayValue(e.target.value);
        props.onChange(e.target.value.trim() ? Math.round(parseFloat(e.target.value) * 100) : 0);
    };

    return (
        <FormControl {...props.rootProps}>
            <InputLabel component="label" {...props.inputLabelProps}>{props.label}</InputLabel>
            <Input {...props.inputProps}
                   type="number"
                   value={displayValue}
                   onChange={handleChange}
                   startAdornment={<InputAdornment position="start">£</InputAdornment>}
            />
        </FormControl>
    );
}

AmountInput.propTypes = {
    rootProps: PropTypes.object,
    inputProps: PropTypes.object,
    inputLabelProps: PropTypes.object,
    value: PropTypes.number.isRequired,
    decimals: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
};

AmountInput.defaultProps = {
    decimals: 2,
    label: 'Amount',
};

export default AmountInput;