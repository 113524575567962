import React, {useEffect, useMemo, useState} from 'react';
import API, {useDataApi} from '../api';
import {
    arrayCloneExcludingIndex,
    arrayCloneWithExtraElement,
    arrayCloneWithReplacedElementAtIndex,
    arrayCloneWithUpdatedPropertyAtIndex,
    changeTitle,
    deepClone,
    deepEqual,
    findNextName
} from "../utils/utils";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Paper from "@material-ui/core/Paper";
import {Box} from "@mui/material";
import {PATH_RETIREMENT_SCENARIOS, PATH_RETIREMENT_SCENARIOS_MINE} from "../config";
import ControlButtons from "../UtilComponents/ControlButtons";
import Button from "@material-ui/core/Button/Button";
import {ErrorMessage} from "./ErrorMessage";


function Retirement() {
    useEffect(() => changeTitle('Retirement Planning'), []);

    const [scenariosApiState, retrieveScenarios] = useDataApi(PATH_RETIREMENT_SCENARIOS_MINE, {scenarios: []});
    const [cudState, setCudState] = useState({isSaving: false, isDeleting: false, error: null});
    const [savedScenarios, setSavedScenarios] = useState(null);
    const [localScenarios, setLocalScenarios] = useState(null);
    const [activeScenarioIndex, setActiveScenarioIndex] = useState(null);
    const activeScenario = activeScenarioIndex === null ? null : localScenarios[activeScenarioIndex];
    useEffect(() => {
        console.log('useEffect#scenariosApiState', 'isLoading', scenariosApiState.isLoading, 'data.scenarios.length', scenariosApiState.data.scenarios.length);
        if (!scenariosApiState.isLoading) {
            setSavedScenarios(scenariosApiState.data.scenarios);
            if (savedScenarios === null) {
                console.log('useEffect#setLocalScenarios', 'scenariosApiState.data.scenarios.length', scenariosApiState.data.scenarios.length);
                setLocalScenarios(deepClone(scenariosApiState.data.scenarios));
            }
        }
    }, [scenariosApiState.isLoading, scenariosApiState.data, savedScenarios]);

    if (activeScenarioIndex === null && localScenarios && localScenarios.length > 0) {
        console.log('setActiveScenarioIndex(0)', 'localScenarios.length', localScenarios.length);
        setActiveScenarioIndex(0);
    }

    const unsavedScenarios = useMemo(() => {
        const unsaved = new Set();
        for (let i = 0; i < (localScenarios ? localScenarios.length : 0); i++) {
            if (localScenarios[i].id === 0) {
                unsaved.add(i);
            } else {
                const savedScenario = savedScenarios.find(s => s.id === localScenarios[i].id);
                if (savedScenario && !deepEqual(savedScenario, localScenarios[i])) {
                    unsaved.add(i);
                }
            }
        }
        return unsaved;
    }, [savedScenarios, localScenarios]);


    const handleScenarioChange = (event, newValue) => {
        setActiveScenarioIndex(newValue);
    };

    const handleClearCudErrorClicked = () => {
        setCudState(cs => ({...cs, error: null}));
    };

    const saveScenario = (index) => {
        setCudState(cs => ({...cs, isSaving: true}));
        const onResponse = (expectedStatusCode, onSuccess) => (r) => {
            console.log('saveScenario#response', r);
            if (r.status === expectedStatusCode) {
                onSuccess(r);
                retrieveScenarios();
            } else {
                setCudState(cs => ({...cs, error: `Expected ${expectedStatusCode} response but got: ${r.status}`}));
            }
        };
        const axiosPromise = localScenarios[index].id > 0
            ? API.put(`${PATH_RETIREMENT_SCENARIOS}/${localScenarios[index].id}`, localScenarios[index])
            : API.post(PATH_RETIREMENT_SCENARIOS, localScenarios[index]);
        axiosPromise
            .then(localScenarios[index].id > 0
                ? onResponse(204, () => {
                })
                : onResponse(201, (r) => {
                    const newId = r.headers.location.split('/').pop();
                    setLocalScenarios(ls => arrayCloneWithUpdatedPropertyAtIndex(ls, index, 'id', newId));
                })
            )
            .catch(error => setCudState(cs => ({...cs, error: error.message})))
            .finally(() => setCudState(cs => ({...cs, isSaving: false})));
    };

    const deleteScenario = (index) => {
        const prevScenariosLength = localScenarios.length;
        const updateUi = () => {
            console.log('deleteScenario#updateUi', 'prevScenariosLength', prevScenariosLength);
            setActiveScenarioIndex(asi => prevScenariosLength > 1 ? Math.max(0, asi - 1) : null);
            setLocalScenarios(ls => arrayCloneExcludingIndex(ls, index));
        };
        if (localScenarios[index].id > 0) {
            setCudState(cs => ({...cs, isDeleting: true}));
            API.delete(`${PATH_RETIREMENT_SCENARIOS}/${localScenarios[index].id}`)
                .then(r => {
                    console.log('deleteScenario#response', r);
                    if (r.status === 204) {
                        updateUi();
                        retrieveScenarios();
                    } else {
                        setCudState(cs => ({...cs, error: `Expected 204 response but got: ${r.status}`}));
                    }
                })
                .finally(() => setCudState(cs => ({...cs, isDeleting: false})));
        } else {
            updateUi();
        }
    };

    const addScenario = (name, data) => {
        const prevScenariosLength = localScenarios.length;
        setLocalScenarios(ls => arrayCloneWithExtraElement(ls, {
            id: 0,
            name: findNextName(name, ls.map(x => x.name)),
            data: deepClone(data)
        }));
        setActiveScenarioIndex(prevScenariosLength);
    };

    const addScenarioClicked = () => {
        addScenario("Scenario", {});
    };

    const handleDuplicateClicked = () => {
        addScenario(activeScenario.name, activeScenario.data);
    };

    const handleDeleteClicked = () => {
        deleteScenario(activeScenarioIndex);
    };

    const handleSaveClicked = () => {
        saveScenario(activeScenarioIndex);
    };

    const handleCancelClicked = () => {
        if (activeScenario.id === 0) {
            deleteScenario(activeScenarioIndex);
        } else {
            setLocalScenarios(ls => arrayCloneWithReplacedElementAtIndex(ls, activeScenarioIndex, savedScenarios.find(s => s.id === activeScenario.id)));
        }
    };

    if (scenariosApiState.isLoading) {
        return <Typography>Loading...</Typography>;
    }

    if (scenariosApiState.error) {
        return (
            <>
                <Typography>Could not load retirement scenarios. {scenariosApiState.error}</Typography>
                <Button onClick={() => retrieveScenarios()}>Retry</Button>
            </>);
    }

    console.log('RENDER', 'savedScenarios.length', savedScenarios ? savedScenarios.length : null, 'localScenarios.length', localScenarios ? localScenarios.length : null, 'activeScenarioIndex', activeScenarioIndex);
    return (<>
        {<Typography variant="h4" gutterBottom>Retirement Planning</Typography>}

        <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>

                <Box xs={12}>
                    <Paper>
                        <Box p={2}>
                            <Typography>Scenarios</Typography>
                            <Box display="flex">
                                {(localScenarios && localScenarios.length > 0 && activeScenarioIndex !== null) &&
                                    <Tabs value={activeScenarioIndex} onChange={handleScenarioChange}>
                                        {localScenarios.map((s, i) => <Tab key={i} label={s.name + (unsavedScenarios.has(i) ? "*" : "")}/>)}
                                    </Tabs>}
                                <IconButton onClick={addScenarioClicked}><AddIcon/></IconButton>
                            </Box>

                            {(localScenarios && localScenarios.length > 0 && activeScenarioIndex !== null) &&
                                <Box display="flex" justifyContent="flex-end" paddingTop={2}>
                                    <Box marginRight={1}>
                                        <Button variant="contained" color="primary" disableElevation
                                                onClick={handleDuplicateClicked}>Duplicate</Button>
                                    </Box>
                                    <Box>
                                        <ControlButtons onClickSave={handleSaveClicked}
                                                        onClickCancel={handleCancelClicked}
                                                        onClickDelete={handleDeleteClicked}
                                                        isDeleteDisabled={activeScenario.id === 0}
                                                        isSaveDisabled={!unsavedScenarios.has(activeScenarioIndex)}
                                                        isCancelDisabled={!unsavedScenarios.has(activeScenarioIndex)}/>
                                    </Box>
                                </Box>
                            }

                            <ErrorMessage message={cudState.error} onClickClearError={handleClearCudErrorClicked}/>

                        </Box>
                    </Paper>
                </Box>
            </Grid>

        </Grid>

    </>);
}

export default Retirement;
