import {
    amber,
    blue,
    blueGrey,
    brown,
    deepOrange,
    deepPurple,
    green,
    indigo,
    lightBlue,
    lightGreen,
    lime,
    orange,
    pink,
    purple,
    red,
    teal,
    yellow
} from "@material-ui/core/colors";
import {simpleHash} from "./utils";

export const CHART_COLORS = [
    red["500"],
    pink["500"],
    amber["500"],
    purple["500"],
    blue["500"],
    green["500"],
    orange["500"],
    brown["500"],
    indigo["500"],
    lime["500"],
    lightBlue["500"],
    deepPurple["500"],
    deepOrange["500"],
    lightGreen["500"],
    yellow["600"],
    teal["500"],
    blueGrey["500"],
];

export function chartColor(input) {
    if (typeof input === "string") {
        input = simpleHash(input);
    } else if (typeof input === "number") {
        input = Math.abs(Math.round(input));
    } else {
        throw new Error("Input must be a number or string");
    }
    return CHART_COLORS[input % CHART_COLORS.length];
}