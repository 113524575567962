import {useEffect, useRef, useState} from 'react';
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: window._ika_env.REACT_APP_API_URL
});

export const useDataApi = (initialUrl, initialData, cacheBuster = null) => {
    const firstUrl = useRef(initialUrl);
    const [url, setUrl] = useState(initialUrl);
    const [localCacheBust, setLocalCacheBust] = useState(() => new Date().getTime());
    const [state, setState] = useState({
        isLoading: true,
        error: null,
        data: initialData,
    });

    if (initialUrl !== firstUrl.current) {
        firstUrl.current = initialUrl;
        setUrl(initialUrl);
    }

    useEffect(() => {
        console.log('[useDataApi].useEffect', url, 'cacheBuster:', cacheBuster, 'localCacheBust:', localCacheBust);
        const axiosSource = axios.CancelToken.source();
        const fetchData = async () => {
            setState(s => ({...s, isLoading: true, error: null}));
            try {
                const result = await axiosInstance.get(url, {cancelToken: axiosSource.token});
                setState({isLoading: false, error: null, data: result.data});
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error('[useDataApi].fetchData.catch', error);
                    setState(s => ({...s, isLoading: false, error: error.message ? error.message : 'no error message'}));
                } else {
                    console.log('[useDataApi].fetchData.cancel', error.message);
                }
            }
        };
        fetchData();

        return () => axiosSource && axiosSource.cancel(`useEffect.cleanup ${url}`);
    }, [url, cacheBuster, localCacheBust]);

    const getData = function (...args) {
        console.log('[getData]', args);
        if (args.length === 1) {
            setUrl(args[0]);
        } else {
            setLocalCacheBust(new Date().getTime());
        }
    };

    return [state, getData];
};

export default axiosInstance;

