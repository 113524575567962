import {format as formatDate, parse as parseDate, toDate} from "date-fns";

const ccyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 2,
});

const roundedCcyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    maximumFractionDigits: 0,
});

const percentageFormatter = new Intl.NumberFormat('en-GB', {
    style: 'percent',
    minimumFractionDigits: 2,
});

export function formatCcy(amount) {
    return ccyFormatter.format(amount);
}

export function formatPercentage(amount) {
    return percentageFormatter.format(amount);
}

export function formatRoundedCcy(amount) {
    return roundedCcyFormatter.format(amount);
}

export function formatCcyDefault(amount, def) {
    return amount === 0 ? def : ccyFormatter.format(amount);
}

export function currentEpochSecs() {
    return Math.floor(new Date().getTime() / 1000);
}

const VALID_MONTHS = Array.from({length: 12}, (x, i) => padMonth(i + 1));

export function validDate(year, month) {
    return month !== null && year !== null
        && VALID_MONTHS.indexOf(String(month)) !== -1
        && parseInt(year) > 1990
        && parseInt(year) <= new Date().getFullYear();
}

export function padMonth(month) {
    return String(month).padStart(2, '0');
}

export function jsMonthOf(month) {
    return VALID_MONTHS.indexOf(String(month));
}

export function formatYearMonth(ym) {
    return formatDate(parseDate(ym, 'yyyy-MM', toDate(0)), "MMM ''yy");
}

export function formatMonth(m) {
    return formatDate(parseDate(m, 'MM', toDate(0)), "MMM");
}

export function currentMonthYear() {
    return {year: new Date().getFullYear(), month: new Date().getMonth()};
}

// Copied from recharts/es6/util/PureRender#shallowEqual
export function shallowEqual(a, b) {
    /* eslint-disable no-restricted-syntax */
    for (var key in a) {
        if ({}.hasOwnProperty.call(a, key) && (!{}.hasOwnProperty.call(b, key) || a[key] !== b[key])) {
            return false;
        }
    }

    for (var _key in b) {
        if ({}.hasOwnProperty.call(b, _key) && !{}.hasOwnProperty.call(a, _key)) {
            return false;
        }
    }

    return true;
}

export const toMapById = list => {
    return list.reduce((map, x) => {
        map[x.id] = x;
        return map;
    }, {});
};

export function removeUndefined(obj) {
    Object.entries(obj).forEach(([key, val]) => {
        if (val === undefined) {
            delete obj[key];
        }
    });
    return obj;
}

export function stringifyValues(obj) {
    Object.entries(obj).forEach(([key, val]) => {
        obj[key] = String(val);
    });
}

// Copied from https://stackoverflow.com/a/3561711
export function escapeRegex(s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

// Copied from ChatGPT
export function simpleHash(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash = hash & hash; // Convert to 32-bit integer
    }
    return Math.abs(hash); // Ensure positive value
}

// Copied from ChatGPT
export function toTitleCase(str) {
    return str
        .split(" ")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ");
}

// Completed by Copilot
export function findNextName(name, list) {
    const baseName = name.replace(/\s*\d*$/, '');
    const regex = new RegExp(`^${escapeRegex(baseName)}\\s*(\\d+)?$`);
    const matches = list.map(item => item.match(regex)).filter(Boolean);
    if (!list.includes(baseName)) {
        return baseName;
    }
    const maxIndex = Math.max(...matches.map(match => parseInt(match[1] || 1, 10)));
    return `${baseName} ${maxIndex + 1}`;
}

// Completed by Copilot
export function arrayCloneWithExtraElement(arr, element) {
    return [...arr, element];
}

// Copied from ChatGPT
export function arrayCloneExcludingIndex(arr, index) {
    return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

// Copied from ChatGPT
export function arrayCloneWithUpdatedPropertyAtIndex(array, index, key, value) {
    return array.map((item, i) => i === index ? {...item, [key]: value} : item);
}

// Completed by Copilot
export function arrayCloneWithReplacedElementAtIndex(array, index, replacement) {
    return array.map((item, i) => i === index ? deepClone(replacement) : item);
}

// Copied from ChatGPT
export function calculateMonthlyMortgageRepayment(principal, annualRate, years) {
    const monthlyRate = annualRate / 12; // Convert annual rate to monthly
    const totalPayments = years * 12; // Total number of months

    if (monthlyRate === 0) {
        return principal / totalPayments; // Handle zero-interest case
    }

    return (principal * monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) /
        (Math.pow(1 + monthlyRate, totalPayments) - 1);
}

export function calculateMonthlyInterest(principal, annualRate) {
    return principal * annualRate / 12;
}

// Copied from Copilot
export const deepClone = obj => {
    if (obj === null || typeof obj !== 'object') {
        return obj;
    }
    if (Array.isArray(obj)) {
        return obj.map(deepClone);
    }
    const clonedObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            clonedObj[key] = deepClone(obj[key]);
        }
    }
    return clonedObj;
};

// Copied from ChatGPT
export const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) return true; // If same reference, they are equal
    if (typeof obj1 !== "object" || typeof obj2 !== "object" || obj1 === null || obj2 === null) {
        return false; // If not objects, they must be strictly equal
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false; // Different number of keys

    return keys1.every(key => deepEqual(obj1[key], obj2[key])); // Recursively compare values
};

export function changeTitle(newTitle) {
    const separator = ' :: ';
    document.title = document.title.split(separator)[0] + `${separator}${newTitle}`;
}